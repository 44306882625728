import React, { useEffect, useState, Suspense } from "react";
import MetaTags from 'react-meta-tags';
import { Row, Col, Container } from 'reactstrap';

import HireFooter from "../components/cta/HireFooter";

import { blogPosts } from "./BlogPage";

const renderLoader = () =>
<div className="load__icon-wrap text-center">
  <svg className="load__icon">
    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  </svg>
</div>;

export default function DemoPage(props) {
  const [posts, setPosts] = useState(null);
  const [mainPost, setMainPost] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        let posts = Object.keys(blogPosts).reverse();
        setMainPost(posts[0]);
        
        setPosts(posts.slice(1));
      } catch (e) {
        console.log('error loading', e);
        props.history.push("/");
      }
    }

    onLoad();
  }, [props.history]);
  return <Suspense fallback={renderLoader()}>
      {posts && <React.Fragment>
        <MetaTags>
          <title>Scaling Tech and Product by Sarunas</title>
          <meta name="description" content="Learning and sharing everyday everything about Tech and Product Development!" />
          <meta property="og:title" content="Scaling Tech and Product by Sarunas" />
          <meta property="og:image" content={blogPosts[mainPost].blogImg} />
        </MetaTags>

        <section className="bg-light py-5">
          <Container className="container">
            <Row className="row">
              <Col className="col">
                <div className="d-flex flex-column flex-lg-row no-gutters border rounded bg-white o-hidden">
                  <a href={`/blog/${blogPosts[mainPost].alias}`} className="d-block position-relative bg-gradient col-xl-5">
                    <img className="flex-fill hover-fade-out" src={blogPosts[mainPost].blogImg} alt={blogPosts[mainPost].title} />
                    <div className="divider divider-side bg-white d-none d-lg-block"></div>
                  </a>
                  <div className="p-4 p-md-5 col-xl-7 d-flex align-items-center">
                    <div className="p-lg-4 p-xl-5">
                      <div className="d-flex justify-content-between align-items-center mb-3 mb-xl-4">
                        <a href={`/tag/${blogPosts[mainPost].tag}`} className={`badge badge-pill badge-${blogPosts[mainPost].colour}`}>{blogPosts[mainPost].tag.toUpperCase()}</a>
                        <div className="text-small text-muted">{blogPosts[mainPost].posted}</div>
                      </div>
                      <a href={`/blog/${blogPosts[mainPost].alias}`}>
                        <h1>{blogPosts[mainPost].title}</h1>
                      </a>
                      <p className="lead">
                        {blogPosts[mainPost].intro} 
                      </p>
                      <a href={`/blog/${blogPosts[mainPost].alias}`} className="lead">Read Article</a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container className="container">
            <Row className="row">
{posts.map(key => (
              <Col key={key} className="col-md-6 col-lg-6 mb-3 mb-md-4">
                <div className="card h-100 hover-box-shadow">
                  <a href={`/blog/${blogPosts[key].alias}`} className="d-block bg-gradient rounded-top">
                    <img className="card-img-top hover-fade-out" src={blogPosts[key].thumbImg} alt={blogPosts[key].title} />
                  </a>
                  <div className="card-body">
                    <a href={`/blog/${blogPosts[key].alias}`}>
                      <h3>{blogPosts[key].title}</h3>
                    </a>
                    <p>
                      {blogPosts[key].intro} 
                    </p>
                    <a href={`/blog/${blogPosts[key].alias}`}>Read Story</a>
                  </div>
                  <div className="card-footer d-flex justify-content-between align-items-center">
                    <a href={`/tag/${blogPosts[key].tag}`} className={`badge badge-pill badge-${blogPosts[key].colour}`}>{blogPosts[key].tag.toUpperCase()}</a>
                    <div className="text-small text-muted">{blogPosts[key].posted}</div>
                  </div>
                </div>
              </Col>
))}
            </Row>
          </Container>
        </section>

        <HireFooter />
      </React.Fragment>}
    </Suspense>;
}